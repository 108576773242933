<template lang="pug">
.main-wrapper.grupos-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Mapa de Grupos</b>
    
    ProgressBar(v-if='waitingMapa' mode="indeterminate")
    Panel(v-else :header="`Hierarquia de Grupos`" v-tooltip.top="'Aviso: O sistema não irá salvar um Grupo como pai dele mesmo.'")
        .p-grid.p-col-12.p-align-center.mapa-grupos
            .p-col-4.card-grupo-slot(v-for="g, idx in grupos" :key="g.grupo_pai.id")
                Panel.card-grupo(:header="g.grupo_pai.nome")
                    .p-grid.p-col-12
                        .p-col-10
                            label.form-label Grupos aos quais controla:
                            MultiSelect.multiselect(
                                v-model='g.ids_grupos_filhos'
                                :options='options.grupos_options'
                                optionLabel='label'
                                optionValue='id'
                                placeholder='Selecione'
                            )
                        .p-col-2.ta-center(style="padding-top: 30px")
                            ProgressSpinner.spinner(v-if="waitingSalvarMapa[idx]" strokeWidth='6')
                            Button(v-else label='Salvar'
                                @click="salvar({'grupo_pai': g.grupo_pai.id, 'grupos_filhos': g.ids_grupos_filhos}, idx)" style='max-width:120px')

</template>
    
    <style lang="scss">
        .mapa-grupos{
            .card-grupo-slot {
                padding: 5px;
            }
            .card-grupo {
                border-radius: 8px;
                .multiselect {
                    width: 100%;
                }
            }
            .spinner{
                height: 25px;
            }
        }
        .p-tooltip {
            max-width: none;
        }
    </style>
    
    <script>
        import MultiSelect from 'primevue/multiselect'
        import ProgressBar from 'primevue/progressbar'
        import DataView from 'primevue/dataview'
        import Panel from 'primevue/panel'
        import Paginator from 'primevue/paginator'
        import DataTable from 'primevue/datatable'
        import Column from 'primevue/column'
        import Button from 'primevue/button'
        import Tooltip from 'primevue/tooltip'
        import Dialog from 'primevue/dialog'
        import InputText from 'primevue/inputtext'
        import ProgressSpinner from 'primevue/progressspinner'
        import Card from 'primevue/card';
        import { Grupos } from './../../middleware'
    
        export default {
            created () { this.getGrupos(); },
            components: { ProgressBar, DataView, Panel, Paginator, DataTable,
                Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Card, MultiSelect },
            directives: { tooltip: Tooltip },
            data () {
                return {
                    grupos: [],
                    waitingMapa: false,
                    waitingSalvarMapa: {},
                    options:{
                        grupos_options: []
                    }
                }
            },
            methods: {
                getGrupos(params) {
                    this.waitingMapa = true;
                    Grupos.mapa(params).then(response => {
                        if (response.status === 200) {
                            this.grupos = response.data;
                            this.options.grupos_options = this.grupos.map(element => ({
                                'id': element.grupo_pai.id,
                                'label': element.grupo_pai.nome
                            }));
                        
                            this.grupos.sort((a, b) => a.grupo_pai.id - b.grupo_pai.id);
                            this.options.grupos_options.sort((a, b) => a.id - b.id);
                        
                            this.waitingSalvarMapa = Array(this.grupos.length).fill(false);
                        }
                        this.waitingMapa = false;
                    });
                },
                salvar(obj_grupo, idx) {
                    this.$set(this.waitingSalvarMapa, idx, true);
                    this.$forceUpdate()
                    Grupos.salvarMapa(obj_grupo).then(response => {
                        this.waitingSalvarMapa[idx] = false;
                        this.$forceUpdate()
                        if (response.status == 200) {
                            console.log(response.data)
                        }
                    });
                }
            }
        }
    </script>